<template>
  <div class="content">
    <div class="pdf">
      <div class="content">
        <el-descriptions title="登录信息" column="1">
          <el-descriptions-item label="选择学校">试用学校</el-descriptions-item>
          <el-descriptions-item label="登录账号">10002300</el-descriptions-item>
          <el-descriptions-item label="登录密码">123456</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <iframe
      :src="src"
      style="border: none; width: 100%; height: 100%"
      id="ifr1"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: "https://talkgpt.talk-gpt.cn:9348", // pdf文件地址
      dialogVisible: true,
    };
  },
};
</script>
<style>
.content {
  width: 100%;
  max-width: 1920px;
  height: 1000px;
  position: relative;
  margin: 0 auto;
}

.pdf {
  position: fixed;
  right: 0;
  top: 200px;
  background-color: #f7f9fa;
  padding: 10px;

  border-radius: 18px 0 0 18px;
  box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    align-items: center;
    width: 150px;
    height: 150px;
    justify-content: center;

    img {
      width: 50px;
      height: auto;
    }

    > :nth-child(2) {
      display: flex;

      span {
        color: #000;
      }
    }
  }
}
</style>
